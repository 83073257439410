

import {mapActions} from "vuex";

export default {
  data() {
    return {
      map_icons: [],
    }
  },
  async fetch() {
    this.map_icons = await this.fetchIcons().then((result) => {
      result.filter((element) => {
        return element.media && element.media.data;
      })
      return result
    });
  },
  fetchOnServer: false,
  methods: {
    ...mapActions({
      fetchIcons: 'map/fetchIcons',
    }),
    iconPosStyle(icon) {
      return {
        left: (icon.position.x / 628.77) * 100 + '%',
        top: (icon.position.y / 400.19) * 100 + '%'
      }
    },
    iconImageUrl(icon) {
      if (icon && icon.media && icon.media.data && icon.media.data.urls && icon.media.data.urls.original) {
        return icon.media.data.urls.original
      }
      return '@/assets/images/default_icon.svg'
    }
  }
}
