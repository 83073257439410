export const createSEOMeta = data => [
  {
    hid: 'og:title',
    property: 'og:title',
    content: data.title || ''
  },
  {
    hid: 'description',
    name: 'description',
    content: data.description || ''
  },
  {
    hid: 'og:description',
    property: 'og:description',
    content: data.description || ''
  },
  {
    hid: 'og:image',
    property: 'og:image',
    content: data.image || process.env.APP_URL + '/icon.png'
  },
  {
    hid: 'og:url',
    property: 'og:url',
    content: data.url ? process.env.APP_URL + data.url : process.env.APP_URL
  },
  {
    hid: 'twitter:title',
    name: 'twitter:title',
    content: data.title || ''
  },
  {
    hid: 'twitter:description',
    name: 'twitter:description',
    content: data.description || ''
  },
  {
    hid: 'twitter:card',
    name: 'twitter:card',
    content: data.cardType || 'summary_large_image'
  },
  {
    hid: 'twitter:image',
    name: 'twitter:image',
    content: data.image || process.env.APP_URL + '/icon.png'
  }
]
