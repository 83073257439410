
import { mapActions, mapGetters } from 'vuex'
import {
  UserIcon,
  MailIcon,
  MessageCircleIcon,
  ArrowRightIcon,
  PhoneIcon,
  AtSignIcon,
  MapPinIcon,
} from 'vue-feather-icons'
import { createSEOMeta } from '~/utils/seo'

export default {
  components: {
    PhoneIcon,
    AtSignIcon,
    UserIcon,
    MailIcon,
    MessageCircleIcon,
    ArrowRightIcon,
    MapPinIcon,
  },
  data() {
    return {
      title: this.$t('home'),
      attractions: [],
      services: [],
      contact_form: {
        name: null,
        email: null,
        phone_number: null,
        message: null,
      },
      form_alert: {
        type: 'success',
        dismiss_secs: 10,
        dismiss_count_down: 0,
        text: '',
      },
    }
  },
  async fetch() {
    this.attractions = await this.fetchAttractions()
    this.services = await this.fetchServices()
  },
  fetchOnServer: false,
  head() {
    return {
      title: this.title,
      meta: [
        ...createSEOMeta({
          title: this.title,
          url: this.$route.path,
        }),
      ],
    }
  },
  computed: {
    ...mapGetters({
      setting: 'settings/setting',
    }),
  },
  methods: {
    ...mapActions({
      fetchAttractions: 'tourist_attractions/fetchAttractions',
      fetchServices: 'services/fetchServices',
      sendMessage: 'contact/sendMessage',
    }),
    getImgUrl(object) {
      if (object.media && object.media.data.length > 0) {
        const firstMedia = object.media.data[0]
        if (firstMedia && firstMedia.urls) {
          if (firstMedia.urls.medium) {
            return firstMedia.urls.medium
          } else if (firstMedia.urls.original) {
            return firstMedia.urls.original
          }
        }
      }

      return '/default-attraction.jpg'
    },
    async submitContactForm() {
      try {
        await this.sendMessage(this.contact_form)
        this.contact_form.name = null
        this.contact_form.email = null
        this.contact_form.message = null
        this.contact_form.phone_number = null
        this.$refs.observer.reset()
        this.showAlert('success', this.$t('contact_us.form.success'))
      } catch (error) {
        if (error.response && error.response.status === 422) {
          this.$refs.observer.setErrors(error.response.data.errors)
        } else {
          this.showAlert('danger', this.$t('contact_us.form.error'))
        }
      }
    },
    alertCountDownChanged(dismissCountDown) {
      this.form_alert.dismiss_count_down = dismissCountDown
    },
    showAlert(type, text) {
      this.form_alert.dismiss_count_down = this.form_alert.dismiss_secs
      this.form_alert.type = type
      this.form_alert.text = text
    },
  },
}
